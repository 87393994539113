/* src/components/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
  background-color: white;
}

.navbar-links {
  display: flex;
  align-items: center;
  flex: 1;
}

.navbar-links_logo {
  margin-right: auto;
}

.navbar-links_logo img {
  width: 230px;
  height: 70px;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.navbar-links_container p a,
.navbar-menu_container p a {
  text-decoration: none; /* Remove underline */
  color: var(--color-text);
}

.navbar-menu {
  display: none;
  position: relative;
  margin-left: auto;
}

.navbar-menu svg {
  cursor: pointer;
  fill: var(--color-text);
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #ddd;
  padding: 1rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.navbar-menu_container p {
  margin: 1rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-links_logo img {
    width: 170px;
    height: 50px;
    
  }

  .navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }

  .navbar-menu_container {
    top: 10px;
  }
}
