/* src/components/Footer.css */

.footer {
  display: flex;
  font-family: var(--font-family);
  background-color: #ddd;
  color: black;
  padding: 20px;
  margin: 20px;
  text-align: left;
}

.footer-container {
  /* display: flex; */
  flex: 1;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}



.footer-image {
 
  justify-content: center;
  align-items: center;
}

.footer img {
  width: auto;
  height: 190px;
}

@media screen and (max-width: 1050px) {
  .footer {
    flex-direction: column;
    align-items: center;  
        
  }

  .footer-container h4{
    text-align: center;
  }
  .footer-container p{
    text-align: center;
  }

}

@media screen and (max-width: 650px) {
  .footer-container {
    justify-items:  center;
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 490px) {
  .footer-container {
    justify-items:  center;
    margin: 0 0 3rem;
  }
}
