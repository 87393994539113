@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  /* --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
   */
  --gradient-text: linear-gradient(90deg, rgba(29, 21, 112, 0.94) 0%, rgba(34, 24, 132, 0.6) 100%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  

  /* --color-bg: #040C18; */
  --color-footer : #000000;
  --color-blog: #000000;
  --color-text: #000000f0;
  /* --color-text: #81AFDD; */
  /* --color-text: #0000008C; */
  --color-subtext: #FF8A71;
}