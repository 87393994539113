/* src/components/Body.css */

.body {
  display: flex;

}

.body-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* margin-right: 1rem; */
}

.body-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 80px;
  letter-spacing: -0.02em;
}

.body-content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;
}

.body-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: left;
  color: var(--color-text);

  /* margin-top: 1rem; */
}

.body-image {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.body-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .body {
    flex-direction: column;

  }

  .body-content {
    align-items: center;
    justify-items:  center;
    margin: 0 0 3rem;
  }



}

@media screen and (max-width: 650px) {
  .body h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .body p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

}

@media screen and (max-width: 490px) {
  .body h1 {
    font-size: 36px;
    line-height: 48px;

  }

  .body p {
    font-size: 14px;
    line-height: 24px;

  }

}
